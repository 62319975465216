import axios from "axios"
export class ServicoConsultaCep {
  constructor() {
    this.urlServico = `https://viacep.com.br/ws/`
  }
  buscaCep(cep) {
    return axios({
      url: this.urlServico + `${cep}/json`,
      method: "GET"
    })
  }
}
