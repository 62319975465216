<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Funcionário"
            name="nome"
            placeholder="Funcionário"
            v-model="funcionario.nome"
            :counter="80"
            :rules="nameRules"
            :readonly="view"
            required
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Telefone 1"
            name="telefone"
            placeholder="(##) #####-####"
            v-model="funcionario.telefone1"
            :counter="15"
            :rules="phoneRules"
            v-mask="'(##) #####-####'"
            :readonly="view"
            required
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Telefone 2"
            name="telefone"
            placeholder="(##) #####-####"
            v-model="funcionario.telefone2"
            :counter="15"
            :rules="phoneRules"
            v-mask="'(##) #####-####'"
            :readonly="view"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="E-mail"
            name="email"
            placeholder="E-mail"
            :rules="emailRules"
            v-model="funcionario.email"
            :counter="80"
            :readonly="view"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-menu
            class="pr-2"
            ref="contDate"
            lazy
            :close-on-content-click="false"
            v-model="contratDate"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="funcionario.dataContratacao"
          >
            <v-text-field
              slot="activator"
              label="Contratação"
              v-model="funcionario.dataContratacao"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="funcionario.dataContratacao" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="contratDate = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.contDate.save(funcionario.dataContratacao)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            class="pr-2"
            ref="reDate"
            lazy
            :close-on-content-click="false"
            v-model="recDate"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="funcionario.dataRecisao"
          >
            <v-text-field
              slot="activator"
              label="Recisão"
              v-model="funcionario.dataRecisao"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="funcionario.dataRecisao" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="recDate = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.reDate.save(funcionario.dataRecisao)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div class="d-flex">
          <v-text-field
            v-if="view"
            class="mr-2"
            label="Cargo"
            name="cargo"
            v-model="funcionario.nomeCargo"
            :readonly="view"
            required
          ></v-text-field>
          <select-cargo v-else class="mr-2"></select-cargo>
        </div>
      </v-flex>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="CPF"
            name="cpf"
            placeholder="###.###.###-##"
            v-model="funcionario.cpf"
            :counter="14"
            :rules="cpfRules"
            v-mask="'###.###.###-##'"
            :readonly="view"
            required
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="RG"
            name="rg"
            v-model="funcionario.rg"
            :counter="10"
            :readonly="view"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-menu
            class="pr-2"
            ref="bornDate"
            lazy
            :close-on-content-click="false"
            v-model="nascimentoDate"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="funcionario.dataNasc"
          >
            <v-text-field
              slot="activator"
              label="Nascimento"
              v-model="funcionario.dataNasc"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="funcionario.dataNasc" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="nascimentoDate = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.bornDate.save(funcionario.dataNasc)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-text-field
            class="mr-2"
            label="Cep"
            name="cep"
            placeholder="Cep"
            v-model="funcionario.cep"
            @blur="buscaCep"
            :counter="8"
            :rules="cepRules"
            :readonly="view"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Rua"
            name="rua"
            placeholder="Rua"
            v-model="funcionario.logradouro"
            :counter="80"
            :readonly="view"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Número"
            name="numero"
            placeholder="Número"
            v-model="funcionario.numero"
            :readonly="view"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Bairro"
            name="bairro"
            placeholder="Bairro"
            v-model="funcionario.bairro"
            :counter="80"
            :readonly="view"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Cidade"
            name="cidade"
            placeholder="Cidade"
            v-model="funcionario.cidade"
            :readonly="view"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Estado"
            name="estado"
            placeholder="Estado"
            v-model="funcionario.estado"
            :readonly="view"
          ></v-text-field>
        </div>
      </v-flex>
      <div class="form-btn">
        <v-btn v-if="!this.view" outline @click="submit" color="primary">Salvar</v-btn>
        <v-btn v-if="!this.view" outline @click="clear">Limpar</v-btn>
        <router-link :to="{ name: 'Funcionario' }" class="nounderline">
          <v-btn outline>Voltar</v-btn>
        </router-link>
      </div>
    </v-layout>
  </v-form>
</template>
<script>
import { ServicoFuncionario } from "../../servicos/servicoFuncionario"
import { ServicoConsultaCep } from "../../servicos/servicoConsultaCep"
import Funcionario from "../../domain/funcionario/Funcionario.js"
import SelectCargo from "../cargo/selectCargo.vue"
const servicoFuncionario = new ServicoFuncionario()
const servicoConsultaCep = new ServicoConsultaCep()
export default {
  components: {
    SelectCargo
  },
  data: () => ({
    id: null,
    view: false,
    funcionario: new Funcionario(),
    nascimentoDate: false,
    startDate: null,
    modal: false,
    nameRules: [
      v => !!v || "Nome é obrigatório",
      v => v.length <= 80 || "Nome não deve possuir mais que 80 caracteres",
      v => v.length >= 3 || "Nome não deve possuir menos que 3 caracteres"
    ],
    emailRules: [v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || v.length == 0 || "E-mail inválido"],
    phoneRules: [v => v.length >= 15 || v.length == 0 || "Telefone deve possuir 14 ou 15 dígitos"],
    cpfRules: [
      v => !!v || "CPF é obrigatório",
      v => v.length >= 14 || "CPF deve possuir 14 dígitos",
      v => v.length <= 14 || "CPF deve possuir 14 dígitos"
    ],
    cepRules: [
      v => v.length >= 8 || v.length == 0 || "Cep deve possuir 8 dígitos",
      v => v.length <= 8 || "Cep deve possuir 8 dígitos"
    ],
    valid: true
  }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.funcionario.id = this.id ? parseInt(this.id) : 0
        this.funcionario.codCargo = this.$children[0].$children[6].codCargo
        servicoFuncionario.salvarFuncionario(this.funcionario).then(
          res => {
            if (res.status === 200) {
              this.$router.push({ name: "Funcionario" })
            }
          },
      // eslint-disable-next-line
          err => console.log(err)
        )
      }
    },
    clear() {
      this.funcionario = new Funcionario()
      this.$refs.form.resetValidation()
    },
    closeDialog() {
      this.$parent.isActive = false
    },
    buscaCep() {
      var cep = this.funcionario.cep
      servicoConsultaCep.buscaCep(cep).then(res => {
        if (res.status === 200) {
          this.funcionario.logradouro = res.data.logradouro
          this.funcionario.bairro = res.data.bairro
          this.funcionario.cidade = res.data.localidade
          this.funcionario.estado = res.data.uf
        }
      })
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id

      if (this.$route.name === "view-funcionario") {
        this.view = true
      }
    }
    if (this.id) {
      servicoFuncionario.buscarPorId(this.id).then(res => {
        this.funcionario = res.data
      })
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
