export default class Funcionario {
  constructor(
    id = "",
    codPessoa = 0,
    codCargo = 0,
    ativo = 0,
    dataContratacao = "",
    dataRecisao = null,
    cnpj = "",
    codEndereco = 0,
    cpf = "",
    dataNasc = "",
    email = "",
    nome = "",
    rg = "",
    telefone1 = "",
    telefone2 = "",
    bairro = "",
    cep = "",
    cidade = "",
    estado = "",
    logradouro = "",
    numero = ""
  ) {
    this.id = id
    this.codPessoa = codPessoa
    this.codCargo = codCargo
    this.ativo = ativo
    this.dataContratacao = dataContratacao
    this.dataRecisao = dataRecisao
    this.cnpj = cnpj
    this.codEndereco = codEndereco
    this.cpf = cpf
    this.dataNasc = dataNasc
    this.email = email
    this.nome = nome
    this.rg = rg
    this.telefone1 = telefone1
    this.telefone2 = telefone2
    this.bairro = bairro
    this.cep = cep
    this.cidade = cidade
    this.estado = estado
    this.logradouro = logradouro
    this.numero = numero
  }
}
