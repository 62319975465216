<template>
  <div id="pageCadastroFuncionario">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg11 sm12 xs12>
          <v-card>
            <v-toolbar color="pink" dark flat dense cad>
              <v-toolbar-title v-if="this.id && !this.view" class="subheading">Editar funcionário</v-toolbar-title>
              <v-toolbar-title v-if="this.view" class="subheading">Funcionário</v-toolbar-title>
              <v-toolbar-title v-if="!this.id && !this.view" class="subheading">Novo funcionário</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="">
              <funcionario-form></funcionario-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import FuncionarioForm from "@/components/funcionario/formFuncionario"
export default {
  components: {
    FuncionarioForm
  },
  data: () => ({
    id: null,
    view: false
  }),
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
      if (this.$route.name === "view-funcionario") {
        this.view = true
      }
    }
  }
}
</script>
