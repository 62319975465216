import axios from "axios"
export class ServicoCargo {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Cargo`
  }
  buscarCargos() {
    return axios({
      url: this.urlServico + `/GetAll`,
      method: "GET"
    })
  }
  salvarCargo(id, descricao) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico + "/Salvar", { id: id, descricao: descricao }, config).catch(error => {
      alert("Erro ao salvar um novo cargo " + error)
      return Promise.reject(error)
    })
  }
  buscarPorId(id) {
    return axios({
      url: this.urlServico + `/GetById?id=${id}`,
      method: "GET"
    })
  }
}
