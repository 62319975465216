<template>
  <v-select
    autocomplete
    label="Cargo"
    placeholder="Selecione..."
    :rules="[() => !!codCargo || 'Campo obrigatório']"
    v-model="codCargo"
    :items="cargos"
    item-value="id"
    item-text="descricao"
    required
  ></v-select>
</template>
<script>
import { ServicoCargo } from "../../servicos/servicoCargo"
const servicoCargo = new ServicoCargo()
export default {
  data: () => ({
    cargos: [],
    codCargo: ""
  }),
  created() {
    servicoCargo.buscarCargos().then(
      res => {
        if (res.status === 200) {
          this.cargos = res.data
        }
      },
      // eslint-disable-next-line
      err => console.log(err)
    )
  }
}
</script>
